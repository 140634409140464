import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/login',name: 'login',component: () => import('../views/Login.vue')},
  {path: '/',name: 'main',component: () => import('../views/Main.vue'),
    redirect:'/user'/* 指定默认显示的子路由 */,
    children: [
    {path: '/password',name: 'password',component: () => import('../views/Password.vue')},
    {path: '/user',name: 'user',component: () => import('../views/User.vue')},
    {path: '/order',name: 'order',component: () => import('../views/Order.vue')},
    {path: '/analysis',name: 'analysis',component: () => import('../views/Analysis.vue')},
  ]},
]
// 解决重复点击导航路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token');
  if(to.name !== 'login'){
    if(!token) {
      next({path: '/login'});
    }else {
      next();
    }  
  }else {
    next();
  }
})
export default router
